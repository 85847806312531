@import "import";

body {
  line-height: 1;
  font-family: Veranda, Helvectica, Sans-serif;
  font-size: 100%;
  margin: 0 auto;

  &.static {
    overflow: hidden;
  }
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  text-decoration: none;
}

img.img-responsive {
  width: 100%;
  max-width: 100%;
  height: auto;
}

/*

		The Music Man

*/


.bgwrap {
  width: 100%;
  background-size: cover;

  .home & {
    background: #8c6488 url(/images/bg/home.jpg) top center repeat-y;
  }

  .music-lessons &,
  .ceilidhs & {
    background: rgb(190, 141, 85) url(/images/bg/ceilidhs.jpg) top center repeat-y;
  }

  .tv & {
    background: rgb(10, 158, 146) url(/images/bg/tv.jpg) top center repeat-y;
  }

  .corporate & {
    background: rgb(98, 117, 173) url(/images/bg/corporate.jpg) top center repeat-y;
  }

  .family & {
    background: rgb(210, 71, 50) url(/images/bg/family.jpg) top center repeat-y;
  }

  .set & {
    background: rgb(158, 187, 94) url(/images/bg/set.jpg) top center repeat-y;
  }
.kids-parties &,
  .sing & {
    background: rgb(230, 178, 33) url(/images/bg/sing.jpg) top center repeat-y;
  }

  .weddings & {
    background: rgb(219, 103, 152) url(/images/bg/weddings.jpg) top center repeat-y;
  }

  .feedback &,
  .contact & {
    background: rgb(221, 123, 36) url(/images/bg/contact.jpg) top center repeat-y;
  }

}


.header {
  padding: 10px 0;
  background: $colour-black;
  border-bottom: $colour-white 3px solid;
}

.vcard {
  padding: 8px 0 0 0;
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}

.navbar-toggler:focus {
  outline: none;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after,
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: $colour-white;
}


@include media-breakpoint-down(sm) {
  .menu-wrapper {
    max-width: 100%;

    .col,
    .row,
    .navbar {
      padding: 0
    }

    .navbar .show {
      background: grey;
      height: calc(100Vh - 80px);
      overflow-y: auto;

      .home & {
        background: rgb(0, 92, 133);
      }

      .music-lessons &,
      .ceilidhs & {
        background: rgb(190, 141, 85);
      }

      .tv & {
        background: rgb(10, 158, 146);
      }

      .corporate & {
        background: rgb(98, 117, 173);
      }

      .family & {
        background: rgb(210, 71, 50);
      }

      .set & {
        background: rgb(158, 187, 94);
      }

      .kids-parties &,
      .sing & {
        background: rgb(230, 178, 33);
      }

      .weddings & {
        background: rgb(219, 103, 152);
      }

      .feedback &,
      .contact & {
        background: rgb(221, 123, 36);
      }


    }

    .navbar-nav {
      max-width: 220px;
      margin: 0 auto 30px !important;
    }
  }
}

.navbar {
  .row {
    width: 100%;
  }

  .navbar-nav {
    margin: 0 0 20px 0;
    padding: 0;
    width: 100%;

    li {
      position: relative;
      margin-top: 10px;
      text-transform: lowercase;
      @include media-breakpoint-up(md) {
        display: inline-block;
        text-align: center;
        width: 11%;
        &.active {
          &:before {
            position: absolute;
            display: block;
            content: '';
            top: -21px;
            left: calc(50% - 16px);
            width: 33px;
            height: 17px;
            background-image: url(/images/nav/marker-on.png);
          }
        }
      }


      a {
        color: rgb(255, 255, 255);
        font-weight: bold;
        text-decoration: none;

        @include media-breakpoint-down(sm) {
          display: block;
          height: 100%;

        }

        &:before {
          content: '';
          display: inline-block;
          width: 60px;
          height: 70px;
          background-position: center center;
          background-repeat: no-repeat;
          vertical-align: middle;
          @include media-breakpoint-down(sm) {
            margin-right: 10px;

          }
          @include media-breakpoint-up(md) {
            display: block;
            width: 100%;
            background-position: left 50% bottom 10px;
          }
        }

        &:hover {
          color: rgb(205, 205, 205);
        }
      }

      &.home a {
        &:before {
          background-image: url(/images/nav/home_off.png);
        }

        &.active,
        &:hover {
          &:before {
            background-image: url(/images/nav/home_on.png);
          }
        }
      }

      &.ceilidhs a {
        &:before {
          background-image: url(/images/nav/ceilidhs_off.png);
        }

        &.active,
        &:hover {
          &:before {
            background-image: url(/images/nav/ceilidhs_on.png);
          }
        }
      }

      &.kids-parties a {
        &:before {
          background-image: url(/images/nav/kids_party_off.png);
        }

        &.active,
        &:hover {
          &:before {
            background-image: url(/images/nav/kids_party_on.png);
          }
        }
      }


      &.weddings a {
        &:before {
          background-image: url(/images/nav/weddings_off.png);
        }

        &.active,
        &:hover {
          &:before {
            background-image: url(/images/nav/weddings_on.png);
          }
        }
      }

      &.family a {

        &:before {
          background-image: url(/images/nav/family_off.png);
        }

        &.active,
        &:hover {
          &:before {
            background-image: url(/images/nav/family_on.png);
          }
        }
      }

      &.sing a {
        &:before {
          background-image: url(/images/nav/sing-a-long_off.png);
        }

        &.active,
        &:hover {
          &:before {
            background-image: url(/images/nav/sing-a-long_on.png);
          }
        }
      }

      &.music-lessons a {
        &:before {
          background-image: url(/images/nav/music-lessons_off.png);
        }

        &.active,
        &:hover {
          &:before {
            background-image: url(/images/nav/music-lessons_on.png);
          }
        }
      }

      &.corporate a {
        &:before {
          background-image: url(/images/nav/corporate_off.png);
        }

        &.active,
        &:hover {
          &:before {
            background-image: url(/images/nav/corporate_on.png);
          }
        }
      }

      &.tv a {
        &:before {
          background-image: url(/images/nav/tv-studio_off.png);
        }

        &.active,
        &:hover {
          &:before {
            background-image: url(/images/nav/tv-studio_on.png);
          }
        }
      }

      &.set a {
        &:before {
          background-image: url(/images/nav/set-list_off.png);
        }

        &.active,
        &:hover {
          &:before {
            background-image: url(/images/nav/set-list_on.png);
          }
        }
      }

      &.contact a {
        &:before {
          background-image: url(/images/nav/contact_off.png);

        }

        &.active,
        &:hover {
          &:before {
            background-image: url(/images/nav/contact_on.png);
          }
        }
      }
    }
  }
}

.banner {
  margin-bottom: 30px;

  img {
    border: 5px solid rgb(255, 255, 255);
    border-radius: 10px;
  }
}

.banner iframe {
  display: none;
}


.bannerslides {
  border: 5px solid rgb(255, 255, 255);
  margin: 0 0 20px 0;
  position: relative;
  border-radius: 10px;
  box-shadow: 2px 0px 7px rgb(41, 41, 41);

  img {
    border-radius: 7px;
    position: relative;
  }
}


.music-lessons .bannerslides,
.ceilidhs .bannerslides {
  border: 5px solid rgb(200, 149, 89);
}

.tv .bannerslides {
  border: 5px solid rgb(0, 169, 155);
}

.corporate .bannerslides {
  border: 5px solid rgb(104, 116, 176);
}

.family .bannerslides {
  border: 5px solid rgb(223, 64, 43);
}

.set .bannerslides {
  border: 5px solid rgb(157, 194, 88);
}

.kids-parties .bannerslides,
.sing .bannerslides {
  border: 5px solid rgb(248, 183, 37);
}

.weddings .bannerslides {
  border: 5px solid rgb(227, 91, 146);
}

.contact .bannerslides {
  border: 5px solid rgb(232, 115, 36);
}

@include media-breakpoint-down(sm) {
  .main-content {
    margin-top: 30px;
  }
}


.videos {
  background: rgb(255, 255, 255) url(/images/video.jpg) top center no-repeat;;
  float: right;
  height: 390px;
  margin: 0 0 20px 0;
  padding: 5px 10px;
  width: 278px;
}

.vidhead {
  background: rgb(76, 73, 66);
  height: 53px;
  line-height: 53px;
}

.vidhead img {
  float: left;
}

img.vidicon {
  float: right;
  margin: 3px 15px 0 0;
}

.vidlist {
  background: rgb(171, 212, 230);
}


.vid {
  clear: left;
  display: block;
  height: 90px;
  font-size: 12px;
  padding: 10px;
  position: relative;
}

.vid > img {
  border: 3px solid rgb(255, 255, 255);
  float: left;
  margin: 0 10px 0 0;
  width: 135px;
  border-radius: 5px;
}

.vidim {
  float: left;
  width: 140px;
}

.vidi {
  float: left;
  width: 110px;
}

.vid h3 {
  font-weight: bold;
  margin: 0 0 5px 0;
}

.vid p {
}

.vid a {
  color: rgb(0, 0, 0);
  text-decoration: underline;
}

.vid a:hover {
  text-decoration: none;
}

.vid .arrow {
  bottom: 15px;
  left: 159px;
  position: absolute;

}

.content {
  position: relative;
  margin-bottom: 30px;
  padding: 10px 30px 10px 25px;
  border-radius: 10px;
  background: rgb(255, 255, 255);

  img {
    max-width: 100%;
    height: auto;
  }
}

.feedback .content {
  height: auto;
}

.content p {
  color: rgb(89, 89, 89);
  font-size: 14px;
  line-height: 16px;
  margin: 0 0 17px 0;
}


.content strong {
  font-size: 18px;
  font-weight: bold;
}

.content a {
  color: rgb(19, 19, 19);
}

.content a:hover {
  text-decoration: underline;
}


.tv .content {
  background: #666668 url(/images/tvbg.png) bottom center repeat-x;
  border: 1px solid rgb(170, 170, 170);
}

.tv .content p {
  color: rgb(255, 255, 255);
}

.tv strong {
  color: rgb(136, 198, 187);
  font-size: 14px;
  font-weight: bold;
}


.testimonals {
  margin-bottom: 65px;
  background: rgb(255, 255, 255) url(/images/testimonials-bg.png) bottom center repeat-x;
  padding: 14px 10px 22px 16px;
  position: relative;
  border-radius: 6px;
}

.testimonalshead {
  background: rgb(76, 73, 66);
  margin: 0 0 20px 0;
  padding: 15px 10px;
}


.comment {
  border-top: 1px dotted rgb(100, 100, 100);
  margin: 10px 0;
  padding: 10px 0;
}

.cimg {
  float: left;
  margin: 0 10px 0 0;
}

.name {
  color: rgb(239, 131, 39);
  margin: 10px 0 0 0;
}

.footer {
  background: $colour-black;
  border-top: 3px solid $colour-white;
  margin-top: 50px;
  padding: 20px 0 30px;

  .navbar-nav {
    display: block;
  }

  .sitemap {
    clear: both;
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    text-transform: lowercase;

    li {
      display: inline-block;

      &:before {
        content: '|';
        display: inline-block;
        margin: 0 10px;
      }

      &:first-child:before {
        content: '';
        margin: 0;
      }
    }
  }
}

.sitemap a {
  color: rgb(255, 255, 255);
}


/* Audio Clips */

.audioclips {
  margin: 0;
  padding: 0;

  .row {
    color: rgb(255, 255, 255);
    font-size: 14px;
    line-height: 25px;
    padding: 2px 0 4px 20px;
  }

  .row:nth-child(odd) {
    background: rgb(50, 50, 50);
  }

  .plays {
    background: url(/images/play.png) center right no-repeat;
    padding: 5px 15px 5px 0;
    line-height: 20px;
  }
}

.num {

}

.sep {

}

.track {

}


.play {
  position: relative;
  margin-top: -13px;

  a {
    line-height: 1.3;
    color: rgb(255, 255, 255);

    &:hover {
      color: rgb(200, 200, 200);
    }
  }
}

.jp-audio {

}

div.jp-interface ul.jp-controls a {
  position: absolute;
  right: 0;
}


div.jp-audio div.jp-type-single a.jp-play, div.jp-audio div.jp-type-single a.jp-pause {
  height: 25px;
  width: 45px;
}

.jp-controls {
  //display: inline;
}

div.jp-interface ul.jp-controls li {
  display: inline;
  float: left;
  margin: 0px;
  padding: 0px;
}


div.jp-interface ul.jp-controls a {
  position: absolute;
}

.jp-pause {
  display: none;
}

.jp-play {
  background: url(/images/play.png) top right no-repeat;
}

.jp-play:hover {
  background: url(/images/play.png) top right no-repeat;
}


div.jp-jplayer {
  height: 0;
  width: 0;
}


.pilates .jp-controls {
  float: left;
  text-align: left;
}

.pilates ul.jp-controls a {
  color: rgb(20, 20, 20);
  left: 20px;
}


/* Testimonals Slider */
.slides_container,
.carousel-item {
  li, p {
    font-size: 14px;
    font-style: italic;
    line-height: 17px;
  }

  p {
    margin: 0 0 17px 0;

  }

  ul {
    margin: 0 0 17px;
    padding-left: 15px;
  }

  li {
    margin: 0 0 5px 0;
  }

  h3 {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
  }

  h4 {
    font-style: italic;
    font-size: 16px;
    line-height: 22px;
  }

}

.carousel-item p {
  padding: 0 5px;

  .home & {
    font-size: 22px;
    font-style: italic;
    line-height: 27px;
    margin: 0 0 17px 0;
  }
}


.carousel-control-next {
  background: url(/images/next.png) top left no-repeat;
  height: 25px;
  line-height: 25px;
  padding: 0 0 0 50px;
  top: initial;
  bottom: -45px;
  right: 25px;
  opacity: 1;

  .carousel-control-next-icon {
    display: none;
  }
}


/* Banner Slides */
.carousel-indicators {
  bottom: 0;

  li {
    background: url(/images/pagination.png) bottom left no-repeat;
    display: block;
    float: left;
    height: 21px;
    width: 21px;
    margin: 0 0 0 10px;
    border: 0;

    &.active {
      background-position: top left;
    }
  }


}

/* Form */

form {
  color: rgb(89, 89, 89);
  font-size: 14px;
}

.contactform,
.feedbackform {
  div {
    margin: 5px 0;
  }
}

.contactform legend {
  display: none;
}

div.captcha {
  float: left;
  margin: 0 0 0 110px;
}

.ie6 div.captcha {
  margin: 0 0 0 60px
}

label {
  clear: both;
  float: left;
  display: block;
  font-size: 14px;
  line-height: 22px;
  margin: 0 10px 0 0;
  width: 100px;
}

input, textarea {
  width: 470px;
}

.captcha input {
  width: 470px;
}

.captcha p {

  display: none;
}

.nav li.tv.png_bg {
  display: none;
}


form {
  color: rgb(89, 89, 89);
  font-size: 14px;
}

.contactform div {
  margin: 5px 0;
}

.contactform legend {
  display: none;
}

div.captcha {
  float: left;
  margin: 0 0 0 110px;
}

.ie6 div.captcha {
  margin: 0 0 0 60px
}

label {
  display: block;
  font-size: 14px;
  line-height: 22px;
  margin: 0 0 10px 0;
  @include media-breakpoint-up(md) {
    margin: 0 10px 0 0;
    clear: both;
    float: left;
    width: 100px;

  }
}

@include media-breakpoint-up(md) {
  input, textarea {
    width: calc(100% - 120px);
    clear: right;
  }
}

.captcha input {
  width: 470px;

  p {
    display: none;
  }
}

.submit {
  text-align: right;
  display: block;
  clear: both;
  position: relative;

  input {
    background: url(/images/submit.jpg) top left no-repeat;
    border: none;
    height: 30px;
    margin: 10px 20px 0 0;
    text-indent: -10000px;
    width: 80px;
  }
}

p.error,
.error_message {
  color: red;
  @include media-breakpoint-up(md) {
    margin-left: 120px;

  }
}

.error_message {
  ul {
    padding-left: 0;

  }
}

.module_bfr_browsenav {
  text-align: center;
  margin-top: 30px
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}